<template>
  <v-card class="mb-2">
    <v-card-text>
      <v-row>
        <v-col :cols="12" :md="6">
          <v-autocomplete v-model="major" :items="majorItems" :rules="required" label="Major"></v-autocomplete>
        </v-col>
        <v-col :cols="12" :md="6">
          <v-select v-model="advisor" :items="advisorItems" :hint="advisorItems.length === 2 ? 'Only advisor for this major' : (advisorItems.length === 1 ? 'No advisor options available for this major' : '')" :readonly="advisorItems.length <= 2" label="Requested Advisor" persistent-hint></v-select>
        </v-col>
        <v-col v-if="major === 'IDS'">
          <v-autocomplete v-model="primaryConc" :items="primaryItems" :rules="required" label="Primary Concentration" chips></v-autocomplete>
        </v-col>
        <v-col :cols="major === 'IDS' ? 6 : 12">
          <v-autocomplete v-model="conc" :items="concItems" :label="major === 'IDS' ? 'Additional Concentrations (select 2)' : 'Concentrations (select up to 2)'" :rules="concRules" :disabled="concItems.length === 0" :hint="concItems.length === 0 ? 'No concentrations for the selected major' : ''" persistent-hint clear-icon="fal fa-times-circle" chips deletable-chips clearable multiple></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="hasSecond">
      <v-btn outlined @click="$emit('remove')">
        <v-icon left>fal fa-times-circle</v-icon>
        Remove Major
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api'
import { advisors, concentrations, IDSPrimaryConcentrations } from './options'
import { inArray } from '@/helpers/functions'

export default {
  props: {
    program: {
      type: Object,
      required: true
    },
    // If they have more than one major, this is the option that is already selected by the other major; we will disable it below
    disableMajors: {
      type: Array,
      default: () => { return [] }
    },
    majors: {
      type: Array,
      required: true
    },
    hasSecond: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const major = ref(props.program.major || '')
    watch(major, () => {
      if (major.value !== props.program.major && major.value !== '') {
        conc.value = []
      }
    })
    const majorItems = computed(() => {
      return props.majors.map(({ value, text }) => {
        if (inArray(value, props.disableMajors)) return { value, text, disabled: true }
        else return { value, text }
      })
    })
    const advisor = ref(props.program.advisor || '')
    const conc = ref(props.program.conc || [])
    watch(conc, () => {
      if (conc.value.length > 2) conc.value.splice(2, 1)
    })
    const primaryConc = ref(props.program.primary || '')
    watch(primaryConc, () => {
      if (primaryConc.value !== '' && conc.value.length > 0) {
        for (let i = 0; i < conc.value.length; i++) {
          if (conc.value[i] === primaryConc.value) {
            conc.value.splice(i, 1)
            break
          }
        }
      }
    })

    watch(() => props.program, () => {
      major.value = props.program.major
      advisor.value = props.program.advisor
      conc.value = props.program.conc
      primaryConc.value = props.program.primary || ''
    })

    const advisorItems = computed(() => {
      const arr = [{ value: '', text: '**No Preference**' }]
      if (major.value in advisors) advisors[major.value].forEach((row) => arr.push(row))
      return arr
    })
    watch(advisorItems, () => {
      if (advisorItems.value.length === 2) {
        advisor.value = advisorItems.value[1].value
      } else if (advisorItems.value.length === 1) {
        advisor.value = advisorItems.value[0].value
      } else if (!inArray(advisor.value, advisorItems.value.map(({ value }) => value))) {
        advisor.value = ''
      }
    })
    const primaryItems = computed(() => IDSPrimaryConcentrations)
    const concItems = computed(() => {
      if (major.value === 'IDS') {
        const arr = []
        concentrations.IDS.forEach(({ value, text }) => {
          if (value === primaryConc.value) arr.push({ value, text, disabled: true })
          else arr.push({ value, text })
        })
        return arr
      }
      if (major.value in concentrations) return concentrations[major.value]
      return []
    })

    const required = ref([(v) => !!v || 'Required'])
    const concRules = ref([(v) => v.length === 2 || major.value !== 'IDS' || '2 Concentrations Required'])

    watch([major, advisor, primaryConc, conc], () => emit('update', { major: major.value, advisor: advisor.value, conc: conc.value, primary: (major.value === 'IDS' ? primaryConc.value : null) }))

    return {
      major,
      majorItems,
      advisor,
      conc,
      primaryConc,
      advisorItems,
      primaryItems,
      concItems,
      required,
      concRules
    }
  }
}
</script>
